import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  saveDepartmentServices,
  getDepartmentUser,
} from "../service/DepartmentService";
import { getStaffDetailUrl } from "../utils/Constant";

export default function useDepartmentDetail() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);

  const { tenantId, locationId, departmentId, name } = useParams();

  const [loading, setLoading] = useState(true);

  const [enable, setEnable] = useState(false);

  const [list, setList] = useState([]);

  const [deptUser, setDeptUser] = useState(false);

  //   const [staffsWithFilteration, setStaffsWithFilteration] = useState([]);
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onSaveServices = async () => {
    setLoading(true);
    await saveDepartmentServices(tenantId, locationId, departmentId, services);
    setLoading(false);
  };

  const handleRowClick = (data) => {
    if (data.deleted) {
      return;
    }
    navigate(getStaffDetailUrl(tenantId, locationId, data.id));
  };

  const toggleSelection = (data, deleted) => {
    data.deleted = deleted;
    data.departmentId = localStorage.getItem("departmentId");
    setServices([...services]);
  };

  useEffect(() => {
    fetchDepartmentUser();
  }, [locationId]);

  const handleClickDepartmentSection = (event, section) => {
    if (section === "Department") {
      setEnable(false);
    } else {
      setEnable(true);
    }
  };

  const fetchDepartmentUser = async () => {
    setLoading(true);
    const response = await getDepartmentUser(
      tenantId,
      locationId,
      localStorage.getItem("departmentId")
    );
    setList(response.user);
    setLoading(false);
  };

  const handleAddDepartmentUser = () => {
    setDeptUser(true);
  };
  //   const departmentStaffsWithFilteration = () => {
  //     setStaffsWithFilteration(
  //       list.filter((item) =>
  //         item.firstName.toLowerCase().includes(search.toLowerCase())
  //       )
  //     );
  //   };

  const handleCancelClick = () => {
    setDeptUser(false);
  };

  return {
    services,
    loading,
    toggleSelection,
    onSaveServices,
    name,
    handleClickDepartmentSection,
    enable,
    list,
    handleAddDepartmentUser,
    deptUser,
    departmentId,
    handleCancelClick,
    search,
    handleSearchChange,
    handleRowClick,
    // departmentStaffsWithFilteration,
  };
}
