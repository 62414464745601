import React, {  useState } from "react";
import Layout from "../components/layout/layout";
import ServiceRequestsSection from "../components/sections/service-requests-section";
import ProductRequestsSection from "../components/sections/product-request-section";
import useRequests from "../hooks/useRequests";

export default function Requests({ alert }) {
  const { filteredStatus,
    productFilteredStatus,
    statuses,
    productStatuses, setFilteredStatus,
    setProductFilteredStatus,filteredStatusName} = useRequests()

  const data = [
    {id : '1',
     tabTitle: "Service Requests",
     tabContent:<>
     <ServiceRequestsSection
                filteredStatus={filteredStatus}
                alert={alert}
                filteredStatusName={filteredStatusName}
              />
   </>
    },
    {id : '2',
     tabTitle: "Product Requests",
     tabContent: <>
     <ProductRequestsSection
     filteredStatus={productFilteredStatus}
     alert={alert}
   /> 
     </>
    }
  ]
  const [visibleTab, setVisibleTab] = useState(data[0].id)

  const listTitles = data.map((item) => 
  <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
)       
const listContent = data.map((item) => 
<p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>)


  return (
    <Layout>
      <div className="row my-4">
        <div className="col-12 mb-md-0 mb-4">
          <div className="card w-100">
            <div className="card-header d-sm-flex flex-sm-row flex-column w-100 pb-0 d-flex align-content-center justify-between">
              <div className="col-6">
                <div className="tabs">
                       <ul className="tabs-titles">
                         {listTitles}
                       </ul>
                      
                   </div>
              </div>
              <div className="flex flex-row align-content-center justify-content-md-end flex-wrap">
                <h6 className="my-o pt-2 pe-2">Status :</h6>
                {visibleTab === data[0].id ?<select
                  name="statuses"
                  className="form-select w-md-25 w-50  rounded-5 border border-grey"
                  onChange={(e) => {                                        
                    setFilteredStatus(e.target.value);
                  }}
                >
                  {statuses.map((item, i) => (
                    (item.displayName !== "CLOSED") && (
                      <option value={item.name} key={i} >
                        {item.displayName}
                      </option>
                    )
                  ))}
                </select>:<select
                  name="statuses"
                  className="form-select w-md-25 w-50  rounded-5 border border-grey"
                  onChange={(e) => {
                    setProductFilteredStatus(e.target.value);
                  }}
                >
                  {productStatuses.map((item, i) => (
                    (item.displayName !== "Closed") && (
                      <option value={item.name} key={i}>
                        {item.displayName}
                      </option>
                    )
                  ))}
                </select>}
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="">
                         {listContent}
                       </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
