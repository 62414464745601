import React from "react";

export default function Avatar({ image, name }) {
  const getInitial = () => {
    if (name && name.length > 0) {
      return name.charAt(0);
    }
    return "";
  };
  return (
    <div className="avatar-group">
      <span
        className={`avatar avatar-s rounded-circle ${
          image ? "" : "avatar-bg"
        } m-1`}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={name}
      >
        {image ? (
          <img src={image} alt={name} />
        ) : (
          <span className="avatar-initial">{getInitial()}</span>
        )}
      </span>
    </div>
  );
}
