import React from "react";
import useRequestCount from "../hooks/useRequestCount";
import RequestCountCard from "./cards/request-count-card";

export default function RequestCounts() {
  const { serviceRequestCounts, productRequestCounts } = useRequestCount();
  return (
    <div className="row">
      <h5 className="ms-2">Service Requests</h5>

      <RequestCountCard
        title={"New Requests"}
        count={
          serviceRequestCounts.pendingRequests
            ? serviceRequestCounts.pendingRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Assigned Requests"}
        count={
          serviceRequestCounts.assignedRequests
            ? serviceRequestCounts.assignedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Inprogress Requests"}
        count={
          serviceRequestCounts.inprogressRequests
            ? serviceRequestCounts.inprogressRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Completed Requests"}
        count={
          serviceRequestCounts.completedRequests
            ? serviceRequestCounts.completedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Total Requests"}
        count={
          serviceRequestCounts.totalRequests
            ? serviceRequestCounts.totalRequests -
              serviceRequestCounts.closedRequests
            : "0"
        }
      />

      <h5 className="ms-2">Product Requests</h5>
      <RequestCountCard
        title={"New Requests"}
        count={
          productRequestCounts.pendingRequests
            ? productRequestCounts.pendingRequests
            : "0"
        }
      />

      <RequestCountCard
        title={"Assigned Requests"}
        count={
          productRequestCounts.assignedRequests
            ? productRequestCounts.assignedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Inprogress Requests"}
        count={
          productRequestCounts.inprogressRequests
            ? productRequestCounts.inprogressRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Completed Requests"}
        count={
          productRequestCounts.completedRequests
            ? productRequestCounts.completedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Total Requests"}
        count={
          productRequestCounts.totalRequests -
          productRequestCounts.closedRequests
            ? productRequestCounts.totalRequests -
              productRequestCounts.closedRequests
            : 0
        }
      />
    </div>
  );
}
