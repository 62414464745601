import React, { useState } from "react";
import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";
import Loader from "../general/loader";
import HDiv from "../containers/hdiv";
import Popover from "../popups/popover";
import { getFormattedDateAndTime } from "../../utils/formatters";
import ProcessLoader from "../general/process-loader";
import Avatar from "../general/avatar";
import DataTable from "react-data-table-component";
import StatusSelectGroup from "../inputs/status-elect-group";
import {  updateStatus } from "../../service/ProductRequestService";
import useProductRequestSection from "../../hooks/useProductRequestSection";

export default function ProductRequestsSection({ alert, filteredStatus }) {
 const {list,
    loading,
    staffs,
    showAssignForm,
    setStatusUpdate,
    statuses,
    apiInProgress,
    commentError,
    showCommentForm,
    goToDetail,
    onCancel,
    onClose,
    onAssign,
    assignStaff,
    onComment,
    handleSubmitComment,
    handleComment,statusUpdate,departmentId,comment} = useProductRequestSection(alert,filteredStatus)
  
    
  return (
    <VDiv>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ProductRequest
            filteredStatus={filteredStatus}
            onAssign={onAssign}
            onClick={goToDetail}
            statuses={statuses}
            list={list}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
            onComment={onComment}
          />

          <HDiv>
            {showAssignForm && (
              <Popover
                title={"Select a staff"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
              >
                <div className="table-responsive text-nowrap  w-100">
                  <table className="table striped">
                    <thead>
                      <tr>
                        <th className="text-center">No.</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Department</th>
                        <th className="text-center">Availability</th>
                        <th className="text-center">Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                    {staffs.map(
                        (item, index) =>
                          item.department &&
                          item.department.id === departmentId && (
                            <tr key={item.id} className="v-align-center">
                              <td>{index + 1}</td>
                              <td>
                                {item.user.firstName} {item.user.lastName}
                              </td>
                              <td>
                                {item.department ? item.department.name : ""}
                              </td>
                              <td>Available</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary w-auto mb-0"
                                  onClick={() => assignStaff(item)}
                                >
                                  Assign
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
          </HDiv>




          {showCommentForm && (
              <Popover
                title={"Comment"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
                className="rounded-0"
              >{commentError &&
                <div className="text-bold text-danger">
                  Please Enter Comments
                </div>
                }
                <div className="table-responsive text-nowrap pt-2">
                  <textarea className="w-50vw fs-5 rounded-1 px-2" placeHolder={"Type your comments here"} value={comment} onChange={(event) => handleComment(event)} />
                </div>
                <div className="text-end pt-2">
                  <button
                    type="button"
                    className="btn btn-primary w-auto mb-0"
                    onClick={(event) => handleSubmitComment(event)}
                  >
                    Submit
                  </button>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
        </>
      )}
    </VDiv>
  );
}


function ProductRequest({
  data,
  onClick,
  onAssign,
  no,
  statuses,
  list,
  statusUpdate,
  setStatusUpdate,
  filteredStatus,
  onComment
}) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const { tenantId, locationId } = useParams();

  
  const onStatusChange = async (event, item) => {

      
      setSelectedStatus(event.target.value);
      let status = statuses.filter((item) => {
          return item.id === event.target.value;
        });

    
    if (status.length > 0) {
      // setItem({ ...item, status: status[0] })
      if (status[0].name === "ORDERED") {
        onAssign(event, item); 
      } else {
        const data = {
          id: item.id,
          status: { id: event.target.value },
          doneBy: { id: localStorage.getItem("userId") },
        };
        await updateStatus(tenantId, locationId, data);       
        setStatusUpdate(!statusUpdate);
      }
    }
  };

  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}
        >
          {" "}
          {index + 1}
          {/* {setSelectedStatus(row.status.id)} */}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Product",
      id: "tableHeader",
      selector: (row) => row.product.name,
      sortable: false,
      width: "300px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
          onClick={() => onClick(row)}
        >
          <Avatar name={row.product.name} />
          <strong>{row.product.name}</strong>
        </span>
      ),
    },
    {
      name: "Request From",
      id: "tableHeader",
      selector: (row) => (row.room !== null ? row.room.name : ""),
      sortable: false,
      width: "200px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
        >
          <div>
           
            <span>
              <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
              <p className="text-wrap">{"(" + row.room.floorId.blockId.name + "," + row.room.floorId.name + ")"}</p>
            </span>
          </div>
        </span>
      ),
    },
    {
      name: "Request On",
      id: "tableHeader",
      selector: (row) => <span 
      >{getFormattedDateAndTime(new Date(row.createdOn))}</span>,
      sortable: false,
      width: "200px",
    },
    {
      name: "Assigned To",
      id: "tableHeader",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      sortable: false,
      width: "150px",
    },
    {
      name: "Change Status",
      id: "tableHeader",
      selector: (row) => (
        <div className="w-100">
          <StatusSelectGroup
            className={"w-100 "}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={row.status.id ? row.status.id : selectedStatus}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      width: "160px",
      cell: (row) => (
        <div className="w-100">
          <StatusSelectGroup
            className={"w-100 bg-light"}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={row.status.id ? row.status.id : selectedStatus}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      // sortable: true,
    },
    {
        name: "Comment",
        id: "tableHeader",
        selector: (row) => (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onComment(event, row)}
          >
            <i className="fa fa-commenting-o"></i>
  
          </button>
        ),
        // sortable: true,
        width: "110px",
      },

  ];


  const columns2 = [
    {
        name: "S. No",
        id: "tableHeader",
        cell: (row, index) => (
          <span className={`${row.deleted ? "text-danger" : ""}`}
          >
            {" "}
            {index + 1}
            {/* {setSelectedStatus(row.status.id)} */}
          </span>
        ),
        width: "80px",
        center: "true",
      },
      {
        name: "Product",
        id: "tableHeader",
        selector: (row) => row.product.name,
        sortable: false,
        width: "270px",
        cell: (row) => (
          <span
            className="avatar-container capitalize"
            onClick={() => onClick(row)}
          >
            <Avatar name={row.product.name} />
            <strong>{row.product.name}</strong>
          </span>
        ),
      },
      {
        name: "Request From",
        id: "tableHeader",
        selector: (row) => (row.room !== null ? row.room.name : ""),
        sortable: false,
        width: "250px",
        cell: (row) => (
          <span
            className="avatar-container capitalize"
          //   onClick={() => onClick(row)}
          >
            <div>
             
              <span>
                <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
                <p className="text-wrap">{"(" + row.room.floorId.blockId.name + "," + row.room.floorId.name + ")"}</p>
              </span>
            </div>
          </span>
        ),
      },
      {
        name: "Request On",
        id: "tableHeader",
        selector: (row) => <span 
        >{getFormattedDateAndTime(new Date(row.createdOn))}</span>,
        sortable: false,
        width: "230px",
      },
      {
        name: "Assigned To",
        id: "tableHeader",
        selector: (row) =>
          row.assignedTo ? (
            row.assignedTo.user.firstName
          ) : (
            <button
              type="button"
              className="btn btn-primary w-auto mb-0"
              onClick={(event) => onAssign(event, row)}
            >
              Assign
            </button>
          ),
        sortable: false,
        width: "150px",
      },
      {
          name: "Comment",
          id: "tableHeader",
          selector: (row) => (
            <button
              type="button"
              className="btn btn-primary w-auto mb-0"
              onClick={(event) => onComment(event, row)}
            >
              <i className="fa fa-commenting-o"></i>
    
            </button>
          ),
          // sortable: true,
          width: "110px",
        },
  ]


  
  return (
    <div>
{filteredStatus && list.length ? (
  filteredStatus === "NEW" ? (
    <DataTable
    data={list}
    className="dataTable"
    onRowClicked={onClick}
    columns={columns2}
    pagination
    />
  ) : (
      <DataTable
      data={list}
      className="dataTable"
      onRowClicked={onClick}
      columns={columns}
      pagination
    />
  )
) : (
  <div className="d-flex justify-content-center">
    <p>No Data Found</p>
  </div>
)}
    </div>
  );
}
