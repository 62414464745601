
import React from "react";
import Layout from "../components/layout/layout";
import Card from "../components/cards/card";
import HDiv from "../components/containers/hdiv";
import ProductRequestDetailSection from "../components/sections/product-request-detail-section";
import useProductRequestDetail from "../hooks/useProductRequestDetail";

export default function ProductRequestDetail() {
   const { enable,
    handleClickDepartmentSection} = useProductRequestDetail()
  return (
    <Layout>
      <HDiv className={`flex tab-bar mb-3 py-2`}>
        <div className="d-flex gap-4 justify-content-start ms-4 my-2">
          <h5
            className="shadow-none pull-right m-0 capitalize text-nowrap"
            onClick={(event) => {
              handleClickDepartmentSection(event, "ProductRequest");
            }}
          >
            Product Request - Tracking
          </h5>
        </div>
      </HDiv>
      <Card showAddButton={false}>
        <ProductRequestDetailSection enable={enable} />
      </Card>

    </Layout>
  );
}