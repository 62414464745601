import React from "react";
import Footer from "./footer";
import Header from "./header";
import Menu from "./menu";

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Menu />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header />
        <div className="content-wrapper">
          <div className="container-fluid py-4">{children}</div>
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </main>
    </React.Fragment>
  );
}
