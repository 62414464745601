import React from "react";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import useDepartmentDetail from "../hooks/useDepartmentDetail";
import DataTable from "react-data-table-component";
import NoDataMessage from "../components/general/NoDataMessage";
import Toast from "../components/general/toast";
import Avatar from "../components/general/avatar";
import Card from "../components/cards/card";
// import SearchBox from "../components/layout/search-box";

export default function DepartmentStaffDetails() {
  const {
    loading,
    handleRowClick,
    handleAddDepartmentUser,
    showMessage,
    message,
    // handleSearchChange,
    // staffsWithFilteration,
    list,
  } = useDepartmentDetail();
  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: " Name",
      id: "tableHeader",
      selector: (row) => row.name,
      cell: (row) => (
        <span
          className={`avatar-container ${row.deleted ? "text-danger" : ""}`}
          onClick={() => handleRowClick(row)}
        >
          <Avatar image={row.imageUrl} name={row.firstName} />{" "}
          <strong className="text-tranform-capitalize">
            {row.firstName + " " + row.lastName}
          </strong>
        </span>
      ),
    },
    {
      name: "Email",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}> {row.email}</span>,
    },
    {
      name: "Phone Number",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}> {row.phoneNumber}</span>,
      right: "true",
    },
    {
      name: "Active",
      id: "tableHeader",
      cell: (row) => (
        <span onClick={() => handleRowClick(row)}> {row.active === true ? "Active" : "Inactive"}</span>
      ),
      center: "true",
    },
  ];
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <Card
          title="Staffs manager"
          subTitle={`${localStorage.getItem(
            "departmentName"
          )} Department Staffs`}
          showAddButton={false}
        >
          <>
            <>
              {/* <SearchBox
                width={"w-lg-30 w-md-50 w-sm-75 w-100"}
                placeholder={"Search Staffs"}
                handleSearchChange={handleSearchChange}
              /> */}
              {list && list.length > 0 ? (
                <DataTable
                  data={list}
                  columns={columns}
                  pagination
                  className="dataTable"
                  onRowClicked={handleRowClick}
                />
              ) : (
                <NoDataMessage showAddButton onAdd={handleAddDepartmentUser} />
              )}
            </>
          </>
        </Card>
      )}
      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
