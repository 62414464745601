import  { useEffect, useState } from "react";
import { getProductsOnDepartmentId, saveQuantity } from "../service/ProductService";
import { useParams } from "react-router";

const useProducts = () => {
    const [productList,setProductList] = useState([])
    const [stockDetails,setStockDetails] = useState([])
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [apiInProgress,setApiInProgress] = useState(false)
    const { tenantId, locationId } = useParams();
     const departmentId = localStorage.getItem("departmentId")
    const fetchProducts =async() =>{
      setApiInProgress(true)
        const res =  await getProductsOnDepartmentId(tenantId,locationId,departmentId)
        if(res){
          const filtered = res.filter((item)=>item.deleted === false)
          setApiInProgress(false)
         
          setProductList(filtered)
          setStockDetails([])
        }
        
    }

    const handleInputChange = (value, id, categoryId, departmentId, name, description) => {
        
        setStockDetails((prevValues) => {
          const rowIndex = prevValues.findIndex((item) => item.id === id);
      
          if (rowIndex !== -1) {
            return prevValues.map((item, index) =>
              index === rowIndex
                ? {
                    ...item,
                    value: value,
                    name: name,
                    description: description,
                    categoryId: categoryId,
                    departmentId: departmentId,
                    id:id
                  }
                : item
            );
          }
      
          return [
            ...prevValues,
            {
              id: id,
              value: value,
              name: name,
              description: description,
              categoryId: categoryId,
              departmentId: departmentId,
            },
          ];
        });
      };

      
  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

      const addStock=  () =>{
        const data = {
            stockdetails: stockDetails.map(item => ({
              productQuantity: item.value,
              name: item.name,
              description: item.description,
              category:{
                id:item.categoryId,
              } ,
              department:{
                id:item.departmentId
              },
              product:{
                id:item.id
              } ,
            })),
          };
           saveQuantity(tenantId,locationId,data.stockdetails).then((res)=>{
            
            if(res){
              displayMessage(
                "Stock added successfully"
              );
              fetchProducts(res[0].department.id)
              setStockDetails([])
              
             }
           })
           
      }
      
      
    useEffect(()=>{
        fetchProducts()
    },[])
  return {
    productList,
    handleInputChange,
    addStock,
    message,
    showMessage,
    stockDetails,
    apiInProgress
  }
};

export default useProducts;
