import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import { AppState } from "../state";
import axios from "axios";
import { getAllNotificartionUrl } from "../../service/ApiUrls";
import ConfirmPopupModal from './../popups/confirmationPopup';

export default function Header() {
  // const { appData, setAppData } = useContext(AppState);
  const departmentId = localStorage.getItem("departmentId");
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      getNotification();
    }, 1000);
    // eslint-disable-next-line
  }, [localStorage.getItem("trigger")]);

  const navigate = useNavigate();
  const getAllNotification = async () => {
    const response = await axios.get(getAllNotificartionUrl(departmentId));

    return response;
  };
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const handleSidebar = () => {
    document.querySelector(".g-sidenav-show").classList.add("g-sidenav-pinned");
  };

  const getNotification = async () => {
    const id = localStorage.getItem("userId");
    const response = await getAllNotification(id);
    
    setNotifications(response.data.list);
  };
  // State to manage the visibility of the popup card
  const [showCard, setShowCard] = useState(false);

  // State to manage hover effect on button
  const [isHovered, setIsHovered] = useState(false);

  // Inline styles for the circle image, popup card, and button
  const containerStyle = {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
  };

  const circleStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    overflow: "hidden",
    cursor: "pointer",
    border: "2px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto",
    backgroundColor: "#000000",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: "#000000",
  };

  const popupCardStyle = {
    display: showCard ? "block" : "none",
    position: "absolute",
    top: "85%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "180px",
    padding: "20px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: isHovered ? "#5298E2" : "#007bff",
    color: isHovered ? "##E56666" : "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
    transition: "background-color 0.3s, color 0.3s", // Smooth transition for color change
  };

  // Function to toggle card visibility
  const handleCircleClick = () => {
    setShowCard(!showCard);
  };

  // Function to handle mouse enter event for hover effect
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // Function to handle mouse leave event for hover effect
  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const [businessName, setBuisnessName] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  useEffect(() => {
    setTimeout(() => {
      if (
        localStorage.getItem("BusinessName") === null ||
        localStorage.getItem("BusinessName") === "undefined"
      ) {
        setBuisnessName("");
      } else {
        setBuisnessName(localStorage.getItem("BusinessName"));
      }
      setBusinessImage(localStorage.getItem("BusinessImage"));
    }, 0.0001);
    const cardElement = document.getElementById('card1');

    cardElement.style.backgroundImage = `url('${localStorage.getItem("BusinessImage")}')`;
  }, [localStorage.getItem("BusinessName")]);
  return (
    <>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div
          className="container-fluid py-1 px-3 d-flex justify-content-center
      "
        >
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            {/* <div className="input-group">
            <span className="input-group-text text-body">
              <i className="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Type here..."
            />
          </div> */}

            <div className="position-relative d-flex align-items-center justify-content-start">
              {businessImage !== "null" && businessImage !== "undefined" && (
                <div className="business-logo-header">
                  <div className="card-logo-shadow" id="card1"></div>
                  {/* <div className="business-logo-header mat">
                   <img
                    className="position-relative w-100 pe-1"
                    src={businessImage}
                    alt="img"
                  /> 
                </div> */}
                </div>
              )}
              <p className="ps-1 text-bold text-tranform-capitalize">
                {businessName}
              </p>
            </div>
          </div>

          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item d-flex align-items-center">
                <p className="px-3 text-tranform-capitalize">
                  {localStorage.getItem("departmentName")}
                </p>
              </li>
              <li className="nav-item d-flex align-items-center px-3">
                <div className="nav-link text-body font-weight-bold px-0 cursor-pointer">
                  <div className="dropstart">
                    <i
                      className="fa fa-user me-sm-1 image-pop-zoom-up-big"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></i>
                    {/* <div>
            <a href="/"><h1>logout</h1></a>
          </div> */}

                    {/* <ul className="dropdown menu">
                    <li className="ps-3 d-flex justify-between align-items-center">
                      <a
                        href={`#/t/${localStorage.getItem(
                          "tenantId"
                        )}/l/${localStorage.getItem("locationId")}`}
                      >
                        <p className="text-primary text-bold text-tranform-capitalize">{`${localStorage.getItem(
                          "firstName"
                        )} `}</p>
                      </a>
                    </li>
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#ConfirmModal"
                      className="ps-3 d-flex justify-between align-items-center"
                    >
                      <p>Logout</p>
                      <i className="fa fa-sign-out cursor-pointer pe-3"></i>
                    </li>
                  </ul> */}
                    <div style={containerStyle}>
                      {/* Circle Image */}
                      <div style={circleStyle} onClick={handleCircleClick}>
                        <img
                          src="/assets/images/user-icon.png"
                          alt="Profile"
                          style={imageStyle}
                        />
                      </div>

                      {/* Popup Card that appears on click */}
                      <div style={popupCardStyle}>
                        <h6>{`${localStorage.getItem("firstName")}`}</h6>
                        <button
                          style={buttonStyle}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a
                            href={`#/t/${localStorage.getItem(
                              "tenantId"
                            )}/l/${localStorage.getItem("locationId")}`}
                            className="d-flex align-items-center w-100 text-decoration-none"
                          >
                            <li
                              className="dropdown-item d-flex justify-content-between align-items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#ConfirmModal"
                            >
                              <p className="mb-0 hover">Logout</p>
                              <i className="fa fa-sign-out cursor-pointer"></i>
                            </li>
                          </a>
                        </button>
                      </div>
                    </div>

                    {/* <ul className="a dropdown-menu-end shadow-sm py-2">
                      <li className="dropdown-item d-flex justify-content-between align-items-center">
                        <a
                          href={`#/t/${localStorage.getItem("tenantId")}/l/${localStorage.getItem("locationId")}`}
                          className="d-flex align-items-center w-100 text-decoration-none"
                        >
                          <p className="mb-0 text-primary fw-bold text-capitalize me-auto">{`${localStorage.getItem("firstName")}`}</p>
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown -divider my-2" />
                      </li>
                      <li
                        className="dropdown-item d-flex justify-content-between align-items-center"
                        data-bs-toggle="modal"
                        data-bs-target="#ConfirmModal"
                      >
                        <p className="mb-0">Logout</p>
                        <i className="fa fa-sign-out cursor-pointer"></i>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </li>
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <div
                  className="nav-link text-body p-0"
                  onClick={() => handleSidebar()}
                  id="iconNavbarSidenav"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </div>
              </li>
              {/* <li className="nav-item px-3 d-flex align-items-center">
              <div className="nav-link text-body p-0">
                <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
              </div>
            </li> */}
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <div
                  className="nav-link text-body p-0"
                  // id="dropdownMenuButton"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                >
                  {notifications && notifications.length > 0 ? (
                    <div className="notification-icon">
                      <i className="fas fa-bell cursor-pointer image-pop-zoom-up-big"></i>
                      <div className="notification-dot"></div>
                    </div>
                  ) : (
                    <i className="fa fa-bell cursor-pointer image-pop-zoom-up-big"></i>
                  )}
                </div>
                <ul
                  className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4 h-25vh overflow-auto"
                  aria-labelledby="dropdownMenuButton"
                >
                  {notifications && notifications.length > 0 ? (
                    // eslint-disable-next-line array-callback-return
                    notifications.map((item, index) => {
                      if (item.content == null) {
                        return (
                          <li key={index} className="mb-2">
                            <a
                              className="dropdown-item border-radius-md"
                              href={`#/t/${localStorage.getItem(
                                "tenantId"
                              )}/l/${localStorage.getItem(
                                "locationId"
                              )}/service-requests`}
                            >
                              <div className="d-flex py-1">
                                <div className="my-auto"></div>
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">
                                      {item.subject}
                                    </span>{" "}
                                  </h6>
                                  <p className="text-xs text-secondary mb-0 ">
                                    {item.content
                                      ? item.content.substring(0, 30) + "..."
                                      : `New request for ${item.subject} `}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    })
                  ) : (
                    <div className="text-center">No Notifications</div>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <ConfirmPopupModal
          header={"Are you sure you want to Logout ?"}
          id={"ConfirmModal"}
          confirmButtonLabel={"Logout"}
          confirmOnclick={logout}
        />
      </nav>
    </>
  );
}
