import React from "react"

export default function NoDataMessage({showAddButton, modalId, onAdd}) {

    return(
        <div className="misc-wrapper">
            <h2 className="mb-2 mx-2">No records found!</h2>
            <p className="mb-4 mx-2">Looks like you haven't added any records or zero records found. Click the 'Add' button to add records.</p>
            {
                showAddButton && <button type="button" className="btn btn-primary" onClick={() => onAdd()}>Add</button>
            }
            <div className="mt-4">
                <img
                    src="../assets/img/illustrations/girl-doing-yoga-light.png"
                    alt="girl-doing-yoga-light"
                    width="500"
                    className="img-fluid"
                    data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                    data-app-light-img="illustrations/girl-doing-yoga-light.png"
                />
            </div>
        </div>
    )

}