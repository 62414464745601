import { 
    getProductsBasedOnDepartmentId,
    saveQuantityForProducts,
    getProductRequestsByDepartment
 } from "./ApiUrls";
import { post, get } from "./Service";






export const getProductsOnDepartmentId = async (tenantId, locationId,departmentId) => {
    const response = await get(getProductsBasedOnDepartmentId(tenantId, locationId,departmentId));
    if(!response) {
        return []
    }
    return response;
};

export const saveQuantity = async (tenantId, locationId, data) => {
    const response = await post(saveQuantityForProducts(tenantId, locationId), data);
    return response;
};

export const getProductRequestsByDept = async (tenantId, locationId) => {    
    const response = await get(getProductRequestsByDepartment(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};
