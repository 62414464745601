import  { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProductRequestDetail } from "../service/ProductRequestService";

const useProductRequestDetailSection = () => {
    const { tenantId, locationId, id } = useParams()

    const [productRequest, setProductRequest] = useState(undefined)

    const fetchProductRequest = async () => {
        const response = await getProductRequestDetail(tenantId, locationId, id)
        setProductRequest(response)
    }

    useEffect(() => {
        fetchProductRequest()

    }, [])
  return {
    productRequest
  }
};

export default useProductRequestDetailSection;
