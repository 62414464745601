import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";

import {
  getStaffServices,
  getStaff,
  staffOnlyCount,
} from "../service/StaffService";
// import HDiv from "../components/containers/hdiv";
import DataTable from "react-data-table-component";
import NoDataMessage from "../components/general/NoDataMessage";
import {
  getServiceRequestByStatus,
  getServiceRequestStatuses,
} from "../service/ServiceRequestService";
import { getFormattedDateAndTime } from "../utils/formatters";
import { get } from "../service/Service";
import { getStaffId } from "../service/ApiUrls";
import { getServiceRequestDetaillUrl } from "../utils/Constant";

export default function StaffDetail() {
  // eslint-disable-next-line
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  const { tenantId, locationId, staffId } = useParams();

  const [staffServiceCount, setStaffServiceCount] = useState();

  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [staff, setStaff] = useState({ user: { firstName: "", lastName: "" } });

  const [list, setList] = useState([]);

  const [filteredStatus, setFilteredStatus] = useState("");

  const [statuses, setStatuses] = useState([]);

  const fetchStaff = async () => {
    const getUserIdOfStaff = await get(getStaffId(staffId));
    const response = await getStaff(tenantId, locationId, getUserIdOfStaff.id);
    setStaff(response);
  };

  const staffOnlyCountData = async () => {
    const getUserIdOfStaff = await get(getStaffId(staffId));
    const response = await staffOnlyCount(getUserIdOfStaff.id);
    setStaffServiceCount(response);
  };

  const fetchServices = async () => {
    const response = await getStaffServices(tenantId, locationId, staffId);
    let filteredReponse = response.filter(
      (item) => item.service.deleted === false
    );
    setServices(filteredReponse);
    setLoading(false);
  };

  const handleRowClick = (item) => {
    navigate(getServiceRequestDetaillUrl(tenantId, locationId, item.id));
  };

  useEffect(() => {
    setLoading(true);
    fetchStaff();
    fetchServices();
  }, [locationId]);

  useEffect(() => {
    staffOnlyCountData();
  }, []);

  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Room Name",
      id: "tableHeader",
      cell: (row) => (
        <span onClick={() => handleRowClick(row)}>
          <span className="text-danger">
            {row.room.floorId.blockId.name + "/" + row.room.floorId.name + "/"}
          </span>
          <p>{row.room.name}</p>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Assigned On",
      id: "tableHeader",
      selector: (row) => {        
        
        return <span onClick={() => handleRowClick(row)}>{
          getFormattedDateAndTime(new Date(row.createdOn))}</span>
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Service Name",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}> {row.service.name}</span>,
      center: "true",
    },
    {
      name: "Status",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}> {row.status.displayName}</span>,
      center: "true",
    },
  ];
  const handleServiceRequestByStatus = async () => {
    const getUserIdOfStaff = await get(getStaffId(staffId));
    if (filteredStatus !== "") {
      const response = await getServiceRequestByStatus(
        getUserIdOfStaff.id,
        filteredStatus
      );
      setList(response);
    }
  };
  useEffect(() => {
    handleServiceRequestByStatus();
  }, [filteredStatus]);
  useEffect(() => {
    fetchServiceRequestStatuses();
  }, []);
  const fetchServiceRequestStatuses = async () => {
    const response = await getServiceRequestStatuses(tenantId, locationId);
    setStatuses(response);
    setFilteredStatus(response[1].id);
  };
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <Card
            title={staff.user.firstName + " " + staff.user.lastName}
            showAddButton={false}
          > */}
          <div className="px-3 pb-2 justify-content-center row row-cols-lg-5 row-cols-sm-3 row-cols-1 g-3">
            <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
              Assigned:
              <p className="text-primary fw-bold ps-2">
                {staffServiceCount ? staffServiceCount.assignedCount : ""}
              </p>
            </div>
            <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
              In-Progress:
              <p className="text-primary fw-bold ps-2">
                {staffServiceCount ? staffServiceCount.inProgressCount : ""}
              </p>
            </div>
            <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
              Completed:
              <p className="text-primary fw-bold ps-2">
                {staffServiceCount ? staffServiceCount.completedCount : ""}
              </p>
            </div>
            {/* <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
              Closed:
              <p className="text-primary fw-bold ps-2">
                {staffServiceCount ? staffServiceCount.closedCount : ""}
              </p>
            </div> */}
            <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
              Total:
              <p className="text-primary fw-bold ps-2">
                {staffServiceCount ? staffServiceCount.totalCount - staffServiceCount.closedCount : ""}
              </p>
            </div>
          </div>
          <div className="d-flex flex-md-row flex-column gap-2 justify-content-between w-100 pb-3 pt-4 px-3 bg-white ">
            <div className="font-weight-bold">
              {staff.user.firstName + " " + staff.user.lastName}{" "}
            </div>
            <div className=" d-flex flex-wrap w-auto flex-row">
              <h6 className="my-o pt-2 pe-2">Status :</h6>
              <select
                name="statuses"
                className="rounded rounded-5 border mx-1 ps-2"
                onChange={(e) => {
                  setFilteredStatus(e.target.value);
                }}
              >
                {statuses.map((item, i) => {
                  return (
                    <>
                      {(item.name !== "NEW" && item.name !== "CLOSED") && (
                        <option value={item.id} key={i}>
                          {item.displayName}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
          </div>

          {list && list.length > 0 ? (
            <DataTable
              data={list}
              columns={columns}
              pagination
              className="dataTable"
              onRowClicked={handleRowClick}
            />
          ) : (
            <NoDataMessage />
            //   <p>hhh</p>
          )}
          {/* </Card> */}
        </>
      )}
    </Layout>
  );
}
