import {
  getServiceRequestApiUrl,
  getUpdateServiceRequestApiUrl,
  getServiceRequestAssignApiUrl,
  getServiceRequestStatusesApiUrl,
  getUpdateServiceRequestStatusApiUrl,
  getServiceRequestDetailApiUrl,
  getServiceRequestByDepartment,
  getDepartmentServiceRequestCount,
  getServiceRequestByStatusApiUrl,
} from "./ApiUrls";
import { get, put } from "./Service";

export const getServiceRequests = async (tenantId, locationId) => {
  const response = await get(getServiceRequestApiUrl(tenantId, locationId));
  return response;
};

export const updateServiceRequest = async (tenantId, locationId, data) => {
  const response = await put(
    getUpdateServiceRequestApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const assign = async (tenantId, locationId, data) => {
  const response = await put(
    getServiceRequestAssignApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const updateStatus = async (tenantId, locationId, data) => {
  const response = await put(
    getUpdateServiceRequestStatusApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const getServiceRequestStatuses = async (tenantId, locationId) => {
  const response = await get(
    getServiceRequestStatusesApiUrl(tenantId, locationId)
  );
  return response;
};

export const getServiceRequestDetail = async (tenantId, locationId, id) => {
  const response = await get(
    getServiceRequestDetailApiUrl(tenantId, locationId, id)
  );
  return response;
};

export const getServiceRequestsByDepartmentId = async (
  tenantId,
  locationId,
  id
) => {
  const response = await get(
    getServiceRequestByDepartment(tenantId, locationId, id)
  );
  return response;
};

export const getServiceRequestCount = async (tenantId, locationId, id) => {
  const response = await get(
    getDepartmentServiceRequestCount(tenantId, locationId, id)
  );
  return response;
};

export const getServiceRequestByStatus = async (staffId, id) => {
  const response = await get(getServiceRequestByStatusApiUrl(staffId, id));
  return response;
};
