export const getCategoryDetailUrl = (tenantId, locationId, catregoryId) => {
  return `/t/${tenantId}/l/${locationId}/category/${catregoryId}`;
};

export const getFloorDetailUrl = (tenantId, locationId, floorId) => {
  return `/t/${tenantId}/l/${locationId}/floor/${floorId}`;
};

export const getRoomDetailUrl = (tenantId, locationId, roomId, name) => {
  return `/t/${tenantId}/l/${locationId}/room/${roomId}/${name}`;
};

export const getStaffDetailUrl = (tenantId, locationId, staffId) => {
  return `/t/${tenantId}/l/${locationId}/staff/${staffId}`;
};
export const getDepartmentDetailUrl = (
  tenantId,
  locationId,
  departmentId,
  name
) => {
  return `/t/${tenantId}/l/${locationId}/department/${departmentId}/${name}`;
};

export const getServiceRequestDetaillUrl = (tenantId, locationId, id) => {
  return `/t/${tenantId}/l/${locationId}/sr/${id}`;
};

export const getProductRequestDetaillUrl = (tenantId, locationId, id) => {
  return `/t/${tenantId}/l/${locationId}/pr/${id}`;
};

export const getBlockDetailUrl = (tenantId, locationId, id, name) => {
  return `/t/${tenantId}/l/${locationId}/block/${id}/floors/${name}`;
};
