import React from "react";
import Layout from "../components/layout/layout";
import DataTable from "react-data-table-component";
import useProducts from "../hooks/useProducts";
import Avatar from "../components/general/avatar";
import Toast from "../components/popups/toast";
import ProcessLoader from "../components/general/process-loader";

const Products = () => {
    const {productList,handleInputChange,addStock,showMessage,message,stockDetails,apiInProgress} = useProducts()
    const columns = [
        {
          name: "S. No",
          cell: (row, index) => (
            <span className={`${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {index + 1}
            </span>
          ),
          width: "80px",
          center: "true",
        },
        {
          name: "Name",
          selector: (row) => row.name,
          minWidth: "150px",
    
          sortable: false,
          cell: (row) => (
            <span className="avatar-container capitalize">
              <Avatar image={row.coverImage} name={row.name} />{" "}
              <strong>{row.name}</strong>
            </span>
          ),
        },
        {
          name: "Category",
          selector: (row) => (
            <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {row.category ? row.category.name : ""}
            </span>
          ),
          sortable: false,
        },
        {
          name: "Location",
          selector: (row) => (
            <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {row.department ? row.department.name : ""}
            </span>
          ),
          sortable: false,
        },
        {
          name: "Price",
          // selector: (row) => row.price,
          selector: (row) => (
            <span className={`${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {row.price ? row.price : ""}
            </span>
          ),
          width: "80px",
          center: "true",
        },
        {
          name: "Old Price",
          selector: (row) => (
            <span className={`${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {row.wasPrice ? row.wasPrice : ""}
            </span>
          ),
          center: "true",
        },
        {
          name: "Existing Qty",
          selector: (row) => (
            <span className={`${row.deleted ? "text-danger" : ""}`}>
              {" "}
              {row.productQuantity}
            </span>
          ),
          center: "true",
        },
        {
          name: "New Qty",
          selector: (row) => {
            const foundItem = stockDetails.find((item) => item.id === row.id);
            const value = foundItem ? foundItem.value : "";             
            return (
              <input
                type="number"
                className="w-100 form-control"
                onChange={(e) => handleInputChange(e.target.value, row.id, row.category.id, row.department.id, row.name, row.description)}
                value={value}
              />
            );
          },
          center: "true",
        },
       
      ];

  return   <Layout>
    <div className="row my-4">
        <div className="col-12 mb-md-0 mb-4">
          <div className="card w-100">
            <div className="card-header d-sm-flex flex-sm-row flex-column w-100 pb-0 d-flex align-content-center justify-between">
              <div className="col-6 d-flex justify-content-between w-100">
                <h6>Products</h6>
                <div className="col-md-4 text-end">
                                <button type="button" className="btn btn-primary pull-right" onClick={addStock} >Add</button>
                 </div>
              </div>
              
            </div>
            <div className="card-body px-0 pb-2">
            <DataTable data={productList} columns={columns}/>

            </div>
          </div>
        </div>
      </div>
      {showMessage && <Toast text={message} className="success" />}
      {apiInProgress && <ProcessLoader/>}

    </Layout>
};

export default Products;
