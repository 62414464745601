import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useMenu() {
  const [items, setItems] = useState([]);

  const { tenantId, locationId } = useParams();

  const generateMenus = (masterTypes) => {
    let items = [];
    items.push({
      menuName: "menuName0",
      title: "Dashboard",
      link: `#/t/${tenantId}/l/${locationId}`,
      icon: "bx-home-circle",
      type: "menu",
      active: true,
    });
    items.push({
      menuName: "menuName1",
      title: "Requests",
      link: `#/t/${tenantId}/l/${locationId}/service-requests`,
      icon: "bxs-cog",
      type: "menu",
      active: false,
    });
    // items.push({title: "Masters", type: "menu-header"})
    // items.push({title: "Departments", link: `#/t/${tenantId}/l/${locationId}/departments`, icon: "bxs-cog", type: "menu"})
    // items.push({title: "Categories", link: `#/t/${tenantId}/l/${locationId}/categories`, icon: "bx-category", type: "menu"})
    // items.push({title: "Blocks", link: `#/t/${tenantId}/l/${locationId}/blocks`, icon: "bx-category", type: "menu"})
    // items.push({title: "Services", link: `#/t/${tenantId}/l/${locationId}/services`, icon: "bx-cog", type: "menu"})
    // items.push({
    //   menuName: "menuName2",
    //   title: "Product requests",
    //   link: `#/t/${tenantId}/l/${locationId}/product-requests`,
    //   icon: "bx-user",
    //   type: "menu",
    //   active: false,
    // });
    items.push({
      menuName: "menuName3",
      title: "Products",
      link: `#/t/${tenantId}/l/${locationId}/products`,
      icon: "bx-user",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName4",
      title: "Staffs",
      link: `#/t/${tenantId}/l/${locationId}/staffs`,
      icon: "bx-user",
      type: "menu",
      active: false,
    });
   
    // items.push({title: "Products", link: `#/t/${tenantId}/l/${locationId}/products`, icon: "bx-cog", type: "menu"})

    if (localStorage.getItem("menuName") !== null) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].active === true) {
          items[i].active = false;
        }
      }
      for (let i = 0; i < items.length; i++) {
        if (`#/${window.location.href.split("/#/")[1]}` === items[i].link) {
          items[i].active = true;
          localStorage.setItem("menuName", items[i].menuName);
        } else if (
          `#/${window.location.href.split("/#/")[1]}`.includes("/service-requests/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/sr/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/staff/")
        ) {
          if (localStorage.getItem("menuName") === items[i].menuName) {
            items[i].active = true;
          }
        } else {
          items[i].active = false;
        }
      }
    }

    setItems(items);
  };
  useEffect(() => {
    const newArr = [...items];
    for (let j = 0; j < newArr.length; j++) {
      if (`#/${window.location.href.split("/#/")[1]}` === newArr[j].link) {
        newArr[j].active = true;
        localStorage.setItem("menuName", newArr[j].menuName);
      } else if (
        `#/${window.location.href.split("/#/")[1]}`.includes("/service-requests/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/sr/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/staff/")

      ) {
        if (localStorage.getItem("menuName") === newArr[j].menuName) {
          newArr[j].active = true;
        }
      } else {
        newArr[j].active = false;
      }
    }

    setItems(newArr);
  }, [window.location.href]);
  useEffect(() => {
    generateMenus();
  }, []);

  return { items };
}
