import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import VDiv from '../containers/vdiv'

import { getServiceRequestDetail } from '../../service/ServiceRequestService'

import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";



export default function ServiceRequestDetailSection({ enable }) {

    const { tenantId, locationId, id } = useParams()

    const [serviceRequest, setServiceRequest] = useState(undefined)

    const fetchServiceRequest = async () => {
        const response = await getServiceRequestDetail(tenantId, locationId, id)
        setServiceRequest(response)
    }

    useEffect(() => {
        fetchServiceRequest()

    }, []) 
    return (
        <>{!enable &&
            <VDiv>
                {serviceRequest &&
                    <>
                        <VDiv className={"row align-items-center"}>
                            <VDiv className={"col-lg-6 col-12"}>
                                <PageTitle text={serviceRequest.service.name} />
                                <span className="mt-2">
                                    <p className="text-danger fw-bold">{serviceRequest.room.name}</p>
                                    <p className="text-wrap fw-bold">{"(" + serviceRequest.room.floorId.blockId.name + ", " + serviceRequest.room.floorId.name + ")"}</p>
                                </span>
                                <p>{serviceRequest.comment} </p>
                                <div className="timeline timeline-one-side mt-3">
                                    {serviceRequest.histories.map(item => <StatusBlock key={item.id} item={item} />)}
                                </div>
                            </VDiv>
                            <VDiv className={"col-lg-6 col-12 h-100"}>
                                {/* <PageTitle text={"Details"} /> */}
                                <div className="p-md-3 p-2 border border-2 rounded shadow-md service-request-details-card">
                                    {/* <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Service name</h5><h5 className="col-lg-6 col-6">{serviceRequest.service.name}</h5>
                                    </div> */}
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Requested on</h5><h5 className="col-lg-6 col-6">{getFormattedDateAndTime(new Date(serviceRequest.histories[0].createdOn))}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Current Status</h5><h5 className="col-lg-6 col-6 text-danger">{serviceRequest.histories[0].status.displayName}</h5>
                                    </div>
                                    {/* {serviceRequest.userName ? */}
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">User name</h5><h5 className="col-lg-6 col-6">{serviceRequest.userName ? serviceRequest.userName : "-"}</h5>
                                    </div>
                                    {/* :
                                        <></>
                                    } */}
                                    {/* {serviceRequest.phoneNumber ? */}
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">User number</h5><h5 className="col-lg-6 col-6">{serviceRequest.phoneNumber ? serviceRequest.phoneNumber : "-"}</h5>
                                    </div>
                                    {/* : <></>
                                    } */}
                                    {/* <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Room</h5><h5 className="col-lg-6 col-6">{serviceRequest.room.name}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Floor</h5><h5 className="col-lg-6 col-6">{serviceRequest.room.floorId.name}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Block</h5><h5 className="col-lg-6 col-6">{serviceRequest.room.floorId.blockId.name}</h5>
                                    </div> */}
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Department</h5><h5 className="col-lg-6 col-6">{serviceRequest.assignedTo ? serviceRequest.assignedTo.department.name : "-"}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Assigned to</h5><h5 className="col-lg-6 col-6">{serviceRequest.assignedTo ? serviceRequest.assignedTo.user.firstName + " " + serviceRequest.assignedTo.user.lastName : "-"}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Assigned on</h5><h5 className="col-lg-6 col-6">{serviceRequest.assignedTo ? getFormattedDateAndTime(new Date(serviceRequest.assignedTo.createdOn)) : "-"}</h5>
                                    </div>
                                </div>
                            </VDiv>
                        </VDiv>
                    </>
                }
            </VDiv>
        }
            {/* {enable &&
                <>
                    <PageTitle text={"Details"} />
                    <div className="p-3">
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Service name</h5><h5 className="col-lg-3 col-6">{serviceRequest.service.name}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Requested on</h5><h5 className="col-lg-3 col-6">{getFormattedDateAndTime(new Date(serviceRequest.histories[serviceRequest.histories.length - 1].createdOn))}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Current Status</h5><h5 className="col-lg-3 col-6 text-danger">{serviceRequest.histories[0].status.displayName}</h5>
                        </div>
                        {serviceRequest.userName ?
                            <div className="d-flex gap-2 py-2">
                                <h5 className="fw-normal col-lg-3 col-6">User name</h5><h5 className="col-lg-3 col-6">{serviceRequest.userName}</h5>
                            </div> :
                            <></>
                        }
                        {serviceRequest.phoneNumber ?
                            <div className="d-flex gap-2 py-2">
                                <h5 className="fw-normal col-lg-3 col-6">User number</h5><h5 className="col-lg-3 col-6">{serviceRequest.phoneNumber}</h5>
                            </div>
                            : <></>
                        }
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Room</h5><h5 className="col-lg-3 col-6">{serviceRequest.room.name}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Floor</h5><h5 className="col-lg-3 col-6">{serviceRequest.room.floorId.name}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Block</h5><h5 className="col-lg-3 col-6">{serviceRequest.room.floorId.blockId.name}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Assigned to</h5><h5 className="col-lg-3 col-6">{serviceRequest.assignedTo ? serviceRequest.assignedTo.user.firstName + " " + serviceRequest.assignedTo.user.lastName : "-"}</h5>
                        </div>
                        <div className="d-flex gap-2 py-2">
                            <h5 className="fw-normal col-lg-3 col-6">Assigned on</h5><h5 className="col-lg-3 col-6">{serviceRequest.assignedTo ? getFormattedDateAndTime(new Date(serviceRequest.assignedTo.createdOn)) : "-"}</h5>
                        </div>
                    </div>
                </>
            } */}
        </>
    )
}


function StatusBlock({ item }) {

    const [openFile, setOpenFile] = useState(false)

    const [selectedFile, setSelectedFile] = useState()


    const onFileClick = (file) => {
        setSelectedFile(file)
        setOpenFile(true)
    }

    const closeFile = (event) => {
        event.stopPropagation()
        setOpenFile(false)
    }

    return (
        <div className="timeline-block mb-3">
            <span className="timeline-step">
                <img src="/assets/images/icons/completed-icon.png" alt="Done" />
            </span>
            <div className="timeline-content">
                <h6 className="text-dark text-sm font-weight-bold mb-0">{item.status.displayName}{item.assignedTo && <span> to <span className="user-name">{item.assignedTo.user.firstName} {item.assignedTo.user.lastName}</span></span>}</h6>
                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{getFormattedDateAndTime(new Date(item.createdOn))}</p>
                {item.comment && <p className="comment">{item.comment}</p>}
                <HDiv className="mt-3 mb-3">
                    {item.attachments.map((attachment) =>
                        <VDiv onClick={() => onFileClick(attachment)} className='file-preview' key={attachment.id} style={{ backgroundImage: `url(${attachment.attachment.mediaUrl})` }}>
                        </VDiv>
                    )}
                </HDiv>
            </div>

            {openFile ?
                <VDiv className="show-file-container">
                    <img className='file' alt={selectedFile.attachment.name} src={selectedFile.attachment.mediaUrl} />
                    <img className='close-btn' src='/assets/images/icons/icon-close-white.png' alt='close' onClick={(event) => closeFile(event)} />
                </VDiv>
                : null
            }


        </div>
    )
}