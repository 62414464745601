import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getServiceRequestCount } from "../service/ServiceRequestService";
import { getProductRequestCount } from "../service/ProductRequestService";

export default function useRequestCount() {
  const { tenantId, locationId } = useParams();
  const departmentId = localStorage.getItem("departmentId");
  const [serviceRequestCounts, setServiceRequestCounts] = useState({
    pendingRequests: "",
    inprogressRequests: "",
    completedRequests: "",
    totalRequests: "",
  });
  const [productRequestCounts, setProductRequestCounts] = useState({
    pendingRequests: "",
    inprogressRequests: "",
    completedRequests: "",
    totalRequests: "",
  });

  const getServiceRequestCounts = async () => {
    const response = await getServiceRequestCount(
      tenantId,
      locationId,
      departmentId
    );
    setServiceRequestCounts((prev) => ({
      ...prev,
      pendingRequests: response.newCount,
      assignedRequests: response.assignedCount,
      inprogressRequests: response.inProgressCount,
      completedRequests: response.completedCount,
      closedRequests: response.closedCount,
      totalRequests: response.totalCount,
    }));
  };
  const getProductRequestCounts = async () => {
    const response = await getProductRequestCount(
      tenantId,
      locationId,
      departmentId
    );
    
    setProductRequestCounts((prev) => ({
      ...prev,
      pendingRequests: response.newCount,
      assignedRequests: response.assignedCount,
      inprogressRequests: response.inProgressCount,
      completedRequests: response.completedCount,
      closedRequests: response.closedCount,
      totalRequests: response.totalCount,
    }));
  };
  useEffect(() => {
    getServiceRequestCounts();
    getProductRequestCounts()
  }, []);
  return {
    serviceRequestCounts,
    productRequestCounts
  };
}
