import React, { useState } from "react";
import VDiv from '../containers/vdiv'
import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";
import useProductRequestDetailSection from "../../hooks/useProductRequestDetailSection";


export default function ProductRequestDetailSection({ enable }) {

   const {productRequest} = useProductRequestDetailSection()
    return (
        <>{!enable &&
            <VDiv>
                {productRequest &&
                    <>
                        <VDiv className={"row align-items-center"}>
                            <VDiv className={"col-lg-6 col-12"}>
                                <PageTitle text={productRequest.product.name} />
                                <span className="mt-2">
                                    <p className="text-danger fw-bold">{productRequest.room.name}</p>
                                    <p className="text-wrap fw-bold">{"(" + productRequest.room.floorId.blockId.name + ", " + productRequest.room.floorId.name + ")"}</p>
                                </span>
                                <p>{productRequest.comment} </p>
                                <div className="timeline timeline-one-side mt-3">
                                    {productRequest.histories.map(item => <StatusBlock key={item.id} item={item} />)}
                                </div>
                            </VDiv>
                            <VDiv className={"col-lg-6 col-12 h-100"}>
                                {/* <PageTitle text={"Details"} /> */}
                                <div className="p-md-3 p-2 border border-2 rounded shadow-md service-request-details-card">
                                  
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Requested on</h5><h5 className="col-lg-6 col-6">{getFormattedDateAndTime(new Date(productRequest.histories[0].createdOn))}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Current Status</h5><h5 className="col-lg-6 col-6 text-danger">{productRequest.histories[0].status && productRequest.histories[0].status.displayName}</h5>
                                    </div>
                                    {/* {serviceRequest.userName ? */}
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">User name</h5><h5 className="col-lg-6 col-6">{productRequest.userName ? productRequest.userName : "-"}</h5>
                                    </div>
                             
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">User number</h5><h5 className="col-lg-6 col-6">{productRequest.phoneNumber ? productRequest.phoneNumber : "-"}</h5>
                                    </div>
                                   
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Department</h5><h5 className="col-lg-6 col-6">{productRequest.assignedTo ? productRequest.assignedTo.department.name : "-"}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Assigned to</h5><h5 className="col-lg-6 col-6">{productRequest.assignedTo ? productRequest.assignedTo.user.firstName + " " + productRequest.assignedTo.user.lastName : "-"}</h5>
                                    </div>
                                    <div className="d-flex gap-2 py-2">
                                        <h5 className="fw-normal col-lg-5 col-5">Assigned on</h5><h5 className="col-lg-6 col-6">{productRequest.assignedTo ? getFormattedDateAndTime(new Date(productRequest.assignedTo.createdOn)) : "-"}</h5>
                                    </div>
                                </div>
                            </VDiv>
                        </VDiv>
                    </>
                }
            </VDiv>
        }
       
        </>
    )
}


function StatusBlock({ item }) {

    const [openFile, setOpenFile] = useState(false)

    const [selectedFile, setSelectedFile] = useState()


    const onFileClick = (file) => {
        setSelectedFile(file)
        setOpenFile(true)
    }

    const closeFile = (event) => {
        event.stopPropagation()
        setOpenFile(false)
    }

    
    return (
        <div className="timeline-block mb-3">
            <span className="timeline-step">
                <img src="/assets/images/icons/completed-icon.png" alt="Done" />
            </span>
            <div className="timeline-content">
                <h6 className="text-dark text-sm font-weight-bold mb-0">{item.status && item.status.displayName}{item.assignedTo && <span> to <span className="user-name">{item.assignedTo.user.firstName} {item.assignedTo.user.lastName}</span></span>}</h6>
                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{getFormattedDateAndTime(new Date(item.createdOn))}</p>
                {item.comment && <p className="comment">{item.comment}</p>}
                <HDiv className="mt-3 mb-3">
                    {item.attachments && item.attachments.map((attachment) =>
                        <VDiv onClick={() => onFileClick(attachment)} className='file-preview' key={attachment.id} style={{ backgroundImage: `url(${attachment.attachment.mediaUrl})` }}>
                        </VDiv>
                    )}
                </HDiv>
            </div>

            {openFile ?
                <VDiv className="show-file-container">
                    <img className='file' alt={selectedFile.attachment.name} src={selectedFile.attachment.mediaUrl} />
                    <img className='close-btn' src='/assets/images/icons/icon-close-white.png' alt='close' onClick={(event) => closeFile(event)} />
                </VDiv>
                : null
            }


        </div>
    )
}