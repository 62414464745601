import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCoRUsEruD4BQORN45gkOE6ZVSLBRaayiI",
  authDomain: "concierge-notification.firebaseapp.com",
  projectId: "concierge-notification",
  storageBucket: "concierge-notification.appspot.com",
  messagingSenderId: "400451816256",
  appId: "1:400451816256:web:a63fba1f08d976830288a5",
  vapidKey:
    "BIYSoqzhLRtMq_onGaAePWrHBPW5QQd7LUXiBDm9U62_u7ipPsTsp3GjAHcCr0s44H8i4Eeb3ifvcyARVdTPguc",
};
const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

let messaging = null
if (isiOS === false) {
  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};
export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = (callback) => {
  onMessage(messaging, (payload) => {
    callback(payload);
  });
};
