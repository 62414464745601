import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VDiv from "../containers/vdiv";
import {
  // getServiceRequests,
  assign,
  getServiceRequestStatuses,
  updateStatus,
  getServiceRequestsByDepartmentId,
} from "../../service/ServiceRequestService";
import { getStaffs } from "../../service/StaffService";
import Loader from "../general/loader";
import HDiv from "../containers/hdiv";
import Popover from "../popups/popover";
import { getFormattedDateAndTime } from "../../utils/formatters";
import ProcessLoader from "../general/process-loader";
import { getServiceRequestDetaillUrl } from "../../utils/Constant";
import Avatar from "../general/avatar";
import DataTable from "react-data-table-component";
import StatusSelectGroup from "../inputs/status-elect-group";


export default function ServiceRequestsSection({ alert, filteredStatus }) {
  const { tenantId, locationId } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [showAssignForm, setShowAssignForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  let [statuses, setStatuses] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);
  const departmentId = localStorage.getItem("departmentId");
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [commentData, setCommentData] = useState({
    "id": "",
    "status": {
      "id": ""
    },
    "comment": "",
    "doneBy": {
      "id": ""
    }
  })


  
  const navigate = useNavigate();
  const fetchServiceRequests = async () => {
    setLoading(true);
    const response = await getServiceRequestsByDepartmentId(
      tenantId,
      locationId,
      departmentId
    );
    if(response){
      setList(response);
      setLoading(false);
    }
  };
  
  
  const fetchServiceRequestStatuses = async () => {
    const response = await getServiceRequestStatuses(tenantId, locationId);
    setStatuses(response);
    setLoading(false);
  };
  const goToDetail = (item) => {
    navigate(getServiceRequestDetaillUrl(tenantId, locationId, item.id));
  };
  const fetchStaffs = async () => {
    const response = await getStaffs(tenantId, locationId,departmentId);
    setStaffs(response);
    fetchServiceRequestStatuses();
  };
  const onCancel = () => {
    setShowAssignForm(false);
  };
  const onClose = () => {
    setShowAssignForm(false);
    setShowCommentForm(false);
    setComment("")
    setCommentData({
      "id": "",
      "status": {
        "id": ""
      },
      "comment": "",
      "doneBy": {
        "id": ""
      }  
    })
    setCommentError(false)
  };
  const onAssign = (event, item) => {
    event.stopPropagation();
    setSelectedRequest(item);
    setShowAssignForm(true);
  };
  const onComment = (event, item) => {
    commentData.id = item.id;
    commentData.status.id = item.status.id;
    commentData.doneBy.id = localStorage.getItem("userId");
    event.stopPropagation();
    setShowCommentForm(true);
  };
  const assignStaff = async (item) => {
    setApiInProgress(true);
    const data = {
      id: selectedRequest.id,
      assignedTo: { id: item.id, user: { id: item.user.id } },
      status: { name: "ASSIGNED" },
      assignedBy: { id: localStorage.getItem("userId") },
      service: { name: selectedRequest.service.name },
      comment: selectedRequest.comment === null ? "" : selectedRequest.comment,
    };
    await assign(tenantId, locationId, data);
    setApiInProgress(false);
    setShowAssignForm(false);
    fetchServiceRequests();
    setLoading(false);
  };
  useEffect(() => {
    fetchServiceRequests();
  }, [statusUpdate, alert, filteredStatus]);


  useEffect(()=>{
     fetchStaffs()
  },[])
  const handleComment = (event) => {
    if (event.target.value.length !== 0) {
      setCommentError(false)
    }
    commentData.comment = event.target.value
    setComment(event.target.value)
  }
  const handleSubmitComment = async () => {
    if (comment !== "") {
      // eslint-disable-next-line 
      const response = await updateStatus(tenantId, locationId, commentData);
      setShowCommentForm(false);
      setComment("")
      setCommentData({
        "id": "",
        "status": {
          "id": ""
        },
        "comment": "",
        "doneBy": {
          "id": ""
        }
      })
    } else {
      setCommentError(true)
    }
  }

  return (
    <VDiv>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ServiceRequest
            onAssign={onAssign}
            onClick={goToDetail}
            filteredStatus={filteredStatus}
            statuses={statuses}
            list={list}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
            onComment={onComment}
          />
          <HDiv>
            {showAssignForm && (
              <Popover
                title={"Select a staff"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
              >
                <div className="table-responsive text-nowrap  w-100">
                  <table className="table striped">
                    <thead>
                      <tr>
                        <th className="text-center">No.</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Department</th>
                        <th className="text-center">Services</th>
                        <th className="text-center">Availability</th>
                        <th className="text-center">Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffs.map(
                        (item, index) =>
                          item.department &&
                          item.department.id === departmentId && (
                            <tr key={item.id} className="v-align-center">
                              <td>{index + 1}</td>
                              <td>
                                {item.user.firstName} {item.user.lastName}
                              </td>
                              <td>
                                {item.department ? item.department.name : ""}
                              </td>
                              <td>{item.service.service.name}</td>
                              <td>Available</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary w-auto mb-0"
                                  onClick={() => assignStaff(item)}
                                >
                                  Assign
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
          </HDiv>
          <HDiv>
            {showCommentForm && (
              <Popover
                title={"Comment"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
                className="rounded-0"
              >{commentError &&
                <div className="text-bold text-danger">
                  Please Enter Comments
                </div>
                }
                <div className="table-responsive text-nowrap pt-2">
                  <textarea className="w-50vw fs-5 rounded-1 px-2" placeHolder={"Type your comments here"} value={comment} onChange={(event) => handleComment(event)} />
                </div>
                <div className="text-end pt-2">
                  <button
                    type="button"
                    className="btn btn-primary w-auto mb-0"
                    onClick={(event) => handleSubmitComment(event)}
                  >
                    Submit
                  </button>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
          </HDiv>
        </>
      )}
    </VDiv>
  );
}
function ServiceRequest({
  data,
  onClick,
  onAssign,
  filteredStatus,
  no,
  statuses,
  list,
  statusUpdate,
  setStatusUpdate,
  onComment
}) {
  const [item, setItem] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const { tenantId, locationId } = useParams();


  
  const onStatusChange = async (event, row) => {
    setSelectedStatus(event.target.value);
    let status = statuses.filter((item) => {
      return item.id === event.target.value;
    });
    if (status.length > 0) {
      setItem({ ...item, status: status[0] });
      if (status[0].name === "ASSIGNED") {
        onAssign(event, item);
      } else {
        const data = {
          id: row.id,
          status: { id: event.target.value },
          doneBy: { id: localStorage.getItem("userId") },
        };
        await updateStatus(tenantId, locationId, data);
        setStatusUpdate(!statusUpdate);
      }
    }
  };
  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => onClick(row)}>
          {" "}
          {index + 1}
          {setSelectedStatus(row.status.id)}
        </span>
      ),
      width: "70px",
      center: "true",
    },
    {
      name: "Service",
      selector: (row) => row.name,
      id: "tableHeader",
      width: "280px",
      cell: (row) => (
        <span className="avatar-container" onClick={() => onClick(row)}>
          <Avatar name={row.service.name} />
          <strong className="text-tranform-capitalize">
            {row.service.name}
          </strong>
          {setItem(row)}
        </span>
      ),
    },
    {
      name: "Request From",
      id: "tableHeader",
      selector: (row) =>
        row.room !== null ? (
          <span onClick={() => onClick(row)}>
            <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
            <p className="text-wrap">{"(" + row.room.floorId.blockId.name + "," + row.room.floorId.name + ")"}</p>
          </span>
        ) : (
          ""
        ),
      // sortable: true,
      width: "200px",
    },
    {
      name: "Request On",
      id: "tableHeader",
      selector: (row) => <span onClick={() => onClick(row)}>{getFormattedDateAndTime(new Date(row.createdOn))}</span>,
      // sortable: true,
      width: "200px",
    },
    {
      name: "Assigned To",
      id: "tableHeader",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      // sortable: true,
      width: "150px",
    },
    {
      name: "Change Status",
      id: "tableHeader",
      selector: (row) =>  (
        <div className="w-90">
          <StatusSelectGroup
            className={"w-100 "}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={row.status.id ? row.status.id : selectedStatus}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      width: "160px",
      cell: (row) => (
        <div className="w-100">
          <StatusSelectGroup
            className={"w-100 bg-light"}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={row.status.id ? row.status.id : selectedStatus}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      // sortable: true,
    },

   
    {
      name: "Comment",
      id: "tableHeader",
      selector: (row) => (
        <button
          type="button"
          className="btn btn-primary w-auto mb-0"
          onClick={(event) => onComment(event, row)}
        >
          <i className="fa fa-commenting-o"></i>

        </button>
      ),
      // sortable: true,
      width: "110px",
    },
  ];
  const columns2 = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => onClick(row)}>
          {" "}
          {index + 1}
          {setSelectedStatus(row.status.id)}
        </span>
      ),
      width: "70px",
      center: "true",
    },
    {
      name: "Service",
      selector: (row) => row.name,
      id: "tableHeader",
      width: "280px",
      cell: (row) => (
        <span className="avatar-container" onClick={() => onClick(row)}>
          <Avatar name={row.service.name} />
          <strong className="text-tranform-capitalize">
            {row.service.name}
          </strong>
          {setItem(row)}
        </span>
      ),
    },
    {
      name: "Request From",
      id: "tableHeader",
      selector: (row) =>
        row.room !== null ? (
          <span onClick={() => onClick(row)}>
            <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
            <p className="text-wrap">{"(" + row.room.floorId.blockId.name + "," + row.room.floorId.name + ")"}</p>
          </span>
        ) : (
          ""
        ),
      // sortable: true,
      width: "250px",
    },
    {
      name: "Request On",
      id: "tableHeader",
      selector: (row) => <span onClick={() => onClick(row)}>{getFormattedDateAndTime(new Date(row.createdOn))}</span>,
      // sortable: true,
      width: "230px",
    },
    {
      name: "Assigned To",
      id: "tableHeader",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      // sortable: true,
      width: "170px",
    },
   
      
    {
      name: "Comment",
      id: "tableHeader",
      selector: (row) => (
        <button
          type="button"
          className="btn btn-primary w-auto mb-0"
          onClick={(event) => onComment(event, row)}
        >
          <i className="fa fa-commenting-o"></i>

        </button>
      ),
      // sortable: true,
      width: "110px",
    },
  ];
  return (
    <div>
     {filteredStatus && list.length ? (
  filteredStatus === "NEW" ? (
    // <div className="d-flex justify-content-center">
    <DataTable
      data={
        filteredStatus
          ? list.filter((item) => item.status.name === filteredStatus)
          : list
      }
      className="dataTable"
      onRowClicked={onClick}
      columns={columns2}
      pagination
    />
    // </div>
  ) : (
      <DataTable
      data={
        filteredStatus
          ? list.filter((item) => item.status.name === filteredStatus)
          : list
      }
      className="dataTable"
      onRowClicked={onClick}
      columns={columns}
      pagination
    />
  )
) : (
  <div className="d-flex justify-content-center">
    <p>No Data Found</p>
  </div>
)}
    </div>
  );
}
