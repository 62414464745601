import { getDepartmentId, LOGIN, NOTIFY_TOKEN_URL } from "./ApiUrls";
import { get, post, post2 } from "./Service";

export async function login(formData) {
  const headers = {
    Authorization: "Basic YXBpOkRKQ29ubmVjdGFwaSEh",
    ContentType: "application/x-www-form-urlencoded; charset=utf-8",
  };
  const result = await post2(LOGIN, formData, headers);
  return result;
}
export async function getDepartment(id) {
  const result = await get(getDepartmentId(id));
  return result;
}

export async function postNotificationToken(data) {
  const result = await post(NOTIFY_TOKEN_URL, data);
  return result;
}
