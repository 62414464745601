import { getDepartmentProductRequestCount, getProductRequestAssignApiUrl, getProductRequestDetailApiUrl, getProductRequestStatus, getProductRequests, getStaffsBasedOnDeptId, getUpdateProductRequestStatusApiUrl, productPagination } from "./ApiUrls";
import { get, put } from "./Service";
  
 
 
  export const getProductRequestStatuses = async (tenantId, locationId) => {
    const response = await get(
      getProductRequestStatus(tenantId, locationId)
    );
    return response;
  };
  
  export const assign = async (tenantId, locationId, data) => {
    const response = await put(
      getProductRequestAssignApiUrl(tenantId, locationId),
      data
    );
    return response;
  };
  
  
 
  export const getProductRequestDetail = async (tenantId, locationId, id) => {
    const response = await get(
      getProductRequestDetailApiUrl(tenantId, locationId, id)
    );
    return response;
  };

  export const getProductRequsts = async (tenantId, locationId,departmentId) => {
    const response = await get(
      getProductRequests(tenantId, locationId,departmentId)
    );
    return response;
  };

  export const updateStatus = async (tenantId, locationId, data) => {
    const response = await put(
        getUpdateProductRequestStatusApiUrl(tenantId, locationId),
      data
    );
    return response;
  };


  export const getProductPagination = async (tenantId, locationId,pageNo,size) => {
    const response = await get(
      productPagination(tenantId, locationId,pageNo,size)
    );
    return response;
  };

  export const getProductRequestCount = async (tenantId, locationId, id) => {
    const response = await get(
      getDepartmentProductRequestCount(tenantId, locationId, id)
    );
    return response;
  };
  export const staffsBasedOnDeptId = async (tenantId, locationId, deptId) => {
    const response = await get(
      getStaffsBasedOnDeptId(tenantId, locationId, deptId)
    );
    return response;
  };