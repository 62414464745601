import  { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getServiceRequestStatuses } from "../service/ServiceRequestService";
import { getProductRequestStatuses } from "../service/ProductRequestService";

const useRequests = () => {
    const { tenantId, locationId } = useParams();
    const [filteredStatus, setFilteredStatus] = useState("");
    const [productFilteredStatus, setProductFilteredStatus] = useState("");
    const [statuses, setStatuses] = useState([]);
    const [productStatuses, setProductStatuses] = useState([]);
    const fetchServiceRequestStatuses = async () => {
      const response = await getServiceRequestStatuses(tenantId, locationId);
      setStatuses(response);
      setFilteredStatus(response[0].name);
    };
    const fetchProductRequestStatuses = async () => {
      const response = await getProductRequestStatuses(tenantId, locationId);
      setProductStatuses(response);
      setProductFilteredStatus(response[0].name);
    };
  
    
    useEffect(() => {
      fetchProductRequestStatuses()
      fetchServiceRequestStatuses();
    }, []);
  
    
    
  return {
     filteredStatus,
     productFilteredStatus,
     statuses,
     productStatuses,
     setFilteredStatus,
     setProductFilteredStatus,
       }
};

export default useRequests;
