import React from "react";
import VDiv from "../containers/vdiv";
import HDiv from "../containers/hdiv";
import HGrow from "../elements/h-grow";
import PageTitle from "../elements/texts/page-title";
import PageSubTitle from "../elements/texts/page-sub-title";
import CloseButton from "../inputs/close-button";

export default function Popover({
  title,
  subTitle,
  onClose,
  children,
  customHeader,
  className,
  showActions,
  onSave,
  onCancel,
  saveLabel = "Save",
}) {
  return (
    <VDiv className="page-popover">
      <VDiv className="popover">
        <PopoverHeader title={title} subTitle={subTitle} onClose={onClose} />
        {customHeader ? customHeader : null}
        <VDiv className={`popover-content ${className ? className : ""}`}>
          {children}
        </VDiv>
        {showActions && (
          <HDiv className="form-footer mt-3">
            <button
              type="button"
              className="col-md-12 btn btn-outline-secondary w-auto m-l-auto mb-0"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ml-3 col-md-12 btn btn-primary w-auto mb-0"
              onClick={() => onSave()}
            >
              {saveLabel}
            </button>
          </HDiv>
        )}
      </VDiv>
    </VDiv>
  );
}

function PopoverHeader({ title, subTitle, children, onClose }) {
  return (
    <HDiv className="flex">
      <VDiv>
        {title ? <PageTitle text={title} /> : null}
        {subTitle ? <PageSubTitle text={subTitle} /> : null}
      </VDiv>
      <HGrow />
      <CloseButton onClick={onClose} />
      {children ? children : null}
    </HDiv>
  );
}
