import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { assign, getProductRequestStatuses, getProductRequsts, staffsBasedOnDeptId, updateStatus } from "../service/ProductRequestService";
import { getProductRequestDetaillUrl } from "../utils/Constant";

const useProductRequestSection = (alert,filteredStatus) => {
    const { tenantId, locationId } = useParams();

    const [list, setList] = useState([]);
  
    const [loading, setLoading] = useState(false);
  
    const [staffs, setStaffs] = useState([]);
  
    const [showAssignForm, setShowAssignForm] = useState(false);
  
    const [statusUpdate, setStatusUpdate] = useState(false);
  
    const [selectedRequest, setSelectedRequest] = useState();
  
    let [statuses, setStatuses] = useState([]);
  
    const [apiInProgress, setApiInProgress] = useState(false);
  
    const [comment, setComment] = useState("");
  
    const [commentError, setCommentError] = useState(false);
  
    const [showCommentForm, setShowCommentForm] = useState(false);
  
  
    const [commentData, setCommentData] = useState({
      "id": "",
      "status": {
        "id": ""
      },
      "comment": "",
      "doneBy": {
        "id": ""
      }
    })
  
  
    const departmentId = localStorage.getItem("departmentId")
    const navigate = useNavigate();
    
    const fetchServiceRequests = async () => {
      setLoading(true);
     await  getProductRequsts(tenantId, locationId,departmentId).then((response)=>{

          
          if(response){
              setList(
                  filteredStatus
                    ?response && response.filter((item) =>item && item.status &&item.status.name && item.status.name === filteredStatus)
                    : response
                );
          }
        
       })
      
      
      
      fetchStaffs();
    };
  
  
    const fetchServiceRequestStatuses = async () => {
      const response = await getProductRequestStatuses(tenantId, locationId);
      setStatuses(response);
      setLoading(false);
    };
  
    const goToDetail = (item) => {
      navigate(getProductRequestDetaillUrl(tenantId, locationId, item.id));
    };
  
    const fetchStaffs = async () => {
      const response = await staffsBasedOnDeptId(tenantId, locationId,departmentId);
      setStaffs(response);
      fetchServiceRequestStatuses();
    };

    const onCancel = () => {
      setShowAssignForm(false);
    };
  
    const onClose = () => {
      setShowAssignForm(false);
      setShowCommentForm(false);
      setComment("")
      setCommentData({
        "id": "",
        "status": {
          "id": ""
        },
        "comment": "",
        "doneBy": {
          "id": ""
        }  
      })
      setCommentError(false)
    };
  
    const onAssign = (event, item) => {
      event.stopPropagation();
      setSelectedRequest(item);
      setShowAssignForm(true);
    };
  
    const assignStaff = async (item) => {    
      setApiInProgress(true);
      const data = {
        id: selectedRequest.id,
        assignedTo: { id: item.id, user: { id: item.user.id } },
        status: { name: "ORDERED" },
        assignedBy: { id: localStorage.getItem("userId") },
        product: { name: selectedRequest.product.name },
        comment: selectedRequest.comment === null ? "" : selectedRequest.comment,
      };
      await assign(tenantId, locationId, data);
      setApiInProgress(false);
      setShowAssignForm(false);
      fetchServiceRequests();
    };
    const onComment = (event, item) => {
      commentData.id = item.id;
      commentData.status.id = item.status.id;
      commentData.doneBy.id = localStorage.getItem("userId");
      event.stopPropagation();
      setShowCommentForm(true);
    };
    useEffect(() => {
      fetchServiceRequests();
    }, [statusUpdate, alert, filteredStatus]);
  
    const handleSubmitComment = async () => {
      if (comment !== "") {
        // eslint-disable-next-line 
        const response = await updateStatus(tenantId, locationId, commentData);
        setShowCommentForm(false);
        setComment("")
        setCommentData({
          "id": "",
          "status": {
            "id": ""
          },
          "comment": "",
          "doneBy": {
            "id": ""
          }
        })
      } else {
        setCommentError(true)
      }
    }
  
    const handleComment = (event) => {
      if (event.target.value.length !== 0) {
        setCommentError(false)
      }
      commentData.comment = event.target.value
      setComment(event.target.value)
    }
    // const pagination  =  async() =>{
    //   await getProductPagination()
    // }
  return {
     list,
     loading,
     staffs,
     showAssignForm,
     setStatusUpdate,
     statuses,
     apiInProgress,
     commentError,
     showCommentForm,
     goToDetail,
     onCancel,
     onClose,
     onAssign,
     assignStaff,
     onComment,
     handleSubmitComment,
     handleComment,
     statusUpdate,
     departmentId,
     comment



  }
};

export default useProductRequestSection;
