
import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { MdError } from 'react-icons/md'

export default function FormError({ message, className, show = false }){
  return (
    <div className={`form-message-container ${className ? className : ''}`}>
        <AnimatePresence mode="wait" initial={false}>
            {show &&
            <motion.p className="message" {...framer_error}>
            <MdError />&nbsp;{message}
            </motion.p>
            }
        </AnimatePresence>
    </div>
  )
}

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
}
