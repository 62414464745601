//const HOST = "http://13.232.191.34:10020"
//const HOST = "http://localhost:10020"
const HOST = "https://dev-api-concierge.gjglobalsoft.com";
export const BASE_AUTH_URL = `${HOST}/`;

// const BASE_ATTACHMENT_URL = `${HOST}/v1/admin/attachment`;

const BASE_CONCIERGE_URL = `${HOST}/v1/admin/tenant/`;

export const LOGIN = `${BASE_AUTH_URL}oauth/token`;

export const getDepartmentId = (userId) => {
  return `${BASE_AUTH_URL}v1/admin/tenant/null/location/null/department-user/${userId}`;
};

// export const UPLOAD_URL = `${BASE_ATTACHMENT_URL}`;

export const getDepartmentsApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department`;
};

export const getUserExistApiUrl = (email) => {
  return `${BASE_AUTH_URL}v1/admin/user/user-exist?email=${email}`;
};

export const getSaveDepartmentApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department`;
};

export const getDeleteDepartmentApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department/${id}`;
};

export const getCategoriesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category`;
};

export const getCategoriesByTypeApiUrl = (tenantId, locationId, type) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/type/${type}`;
};

export const getCategoryApiUrl = (tenantId, locationId, categoryId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${categoryId}`;
};

export const getSaveCategoryApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category`;
};

export const getDeleteCategoryApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${id}`;
};

export const getServicesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service`;
};

export const getSaveServiceApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service`;
};

export const getDeleteServiceApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service/${id}`;
};

export const getFloorsApiUrl = (tenantId, locationId, blockId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/block/${blockId}`;
};

export const getFloorApiUrl = (tenantId, locationId, floorId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${floorId}`;
};

export const getSaveFloorApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor`;
};

export const getDeleteFloorApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${id}`;
};

export const getRoomsApiUrl = (tenantId, locationId, floorId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${floorId}/room`;
};

export const getRoomTypesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/room-type`;
};

export const getRoomApiUrl = (tenantId, locationId, floorId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${floorId}/room/${roomId}`;
};

export const getSaveRoomApiUrl = (tenantId, locationId, floorId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${floorId}/room`;
};

export const getDeleteRoomApiUrl = (tenantId, locationId, floorId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/floor/${floorId}/room/${id}`;
};

export const getSaveRoomServicesApiUrl = (tenantId, locationId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/room/${roomId}/room-service`;
};

export const getRoomServicesApiUrl = (tenantId, locationId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/room/${roomId}/room-service`;
};

export const getSubDepartmentsApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department`;
};

export const getSaveSubDepartmentApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department`;
};

export const getSaveDepartmentServicesApiUrl = (
  tenantId,
  locationId,
  departmentId
) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department/${departmentId}/department-service`;
};

export const getDepartmentServicesApiUrl = (
  tenantId,
  locationId,
  departmentId
) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department/${departmentId}/department-service`;
};

// export const getCreateStaffUserApiUrl = (userId) => {
//   return `${BASE_AUTH_URL}/admin/${userId}/staff`;
// };

export const getStaffsApiUrl = (tenantId, locationId,departmentId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff/department/${departmentId}`;
};


export const getStaffApiUrl = (tenantId, locationId, staffId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff/${staffId}`;
};

// export const getSaveStaffApiUrl = (tenantId, locationId) => {
//   return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff`;
// };

// export const getDeleteStaffApiUrl = (tenantId, locationId, id) => {
//   return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff/${id}`;
// };

export const getSaveStaffServicesApiUrl = (tenantId, locationId, staffId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff/${staffId}/staff-service`;
};

export const getStaffServicesApiUrl = (tenantId, locationId, staffId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/staff/${staffId}/staff-service`;
};

export const getUpdateServiceRequestApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request`;
};

export const getServiceRequestAssignApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request/assign`;
};

export const getServiceRequestApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request`;
};

export const getUpdateServiceRequestStatusApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request/status`;
};
export const getUpdateProductRequestStatusApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request/status`;
};

export const getServiceRequestStatusesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request-status`;
};

export const getServiceRequestDetailApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request/${id}`;
};

export const getServiceRequestByDepartment = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department/${id}/department-service/service-request`;
};

export const getDepartmentServiceRequestCount = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department/${id}/department-service/service-count`;
};
export const getDepartmentProductRequestCount = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request/department-count?departmentId=${id}`;
};
export const getDepartmentStaffsApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department-user/${id}/department-user`;
};
export const NOTIFY_TOKEN_URL = `${BASE_AUTH_URL}v1/notification-tokens`;

export const getAllNotificartionUrl = (id) => {
  return `${BASE_AUTH_URL}v1/notification-history/${id}`;
};

const SUPER_ADMIN_CONCIERGE_URL = `${HOST}/v1/super-admin/`;

export const getAdminDetailsApi = (tenantId) => {
  return `${SUPER_ADMIN_CONCIERGE_URL}business/${tenantId}`;
};

export const getDepartmentUserApiUrl = (tenantId, locationId, departmentId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/department-user/${departmentId}/department-user`;
};

export const getServiceRequestByStatusApiUrl = (staffId, id) => {
  return `${HOST}/v1/staff/${staffId}/service-request/status/${id}`;
};

export const getStaffId = (id) => {
  return `${BASE_AUTH_URL}v1/staff/user/${id}`;
};

export const getStaffOnlyCount = (id) => {
  return `${HOST}/v1/staff/${id}/service-request/staff-service-count`;
};

export const getProductsBasedOnDepartmentId = (tenantId,locationId,departmentId) =>{
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product/department/${departmentId}`
}

export const saveQuantityForProducts = (tenantId,locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-quantity`
}

export const getProductRequestsByDepartment = (tenantId,locationId) => {
  return `${HOST}/v1/department/tenant/${tenantId}/location/${locationId}/product-request`
}

export const getProductRequestStatus = (tenantId,locationId) =>{
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request-status`

}

export const getProductRequestAssignApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request/assign`;
};


export const getProductRequestDetailApiUrl = (tenantId, locationId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request/${id}`;
};


export const getProductRequests = (tenantId,locationId,departmentId) =>{
  return `${HOST}/v1/department/tenant/${tenantId}/location/${locationId}/product-request/department/${departmentId}`

}


export const productPagination = (tenantId,locationId,pageNo,size)=>{
  return `${HOST}/v1/admin/tenant/${tenantId}/location/${locationId}/product-request/pagination?page=${pageNo}&size=${size}`
}
export const getStaffsBasedOnDeptId = (tenantId,locationId,deptId)=>{
  return `${HOST}/v1/admin/tenant/${tenantId}/location/${locationId}/staff/department-product/${deptId}`
}
