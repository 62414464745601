import  { useState } from "react";

const useProductRequestDetail = () => {
    const [enable, setEnable] = useState(false);

    const handleClickDepartmentSection = (event, section) => {
      if (section === "ProductRequest") {
        setEnable(false);
      } else {
        setEnable(true);
      }
    };
  return {
    enable,
    handleClickDepartmentSection
  }
};

export default useProductRequestDetail;
