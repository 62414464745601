// import {
//     getBlocksApiUrl,
//     getSaveBlockApiUrl,
//     getBlockApiUrl,
//     getDeleteBlockApiUrl
//  } from "./ApiUrls";
// import { post, get, put, Delete } from "./Service";

import { getAdminDetailsApi } from "./ApiUrls";
import { get } from "./Service";

// export const getBlocks = async (tenantId, locationId) => {
//     const response = await get(getBlocksApiUrl(tenantId, locationId));
//     if(!response) {
//         return []
//     }
//     return response;
// };

// export const getBlock = async (tenantId, locationId, id) => {
//     const response = await get(getBlockApiUrl(tenantId, locationId, id));
//     if(!response) {
//         return []
//     }
//     return response;
// };

// export const saveBlock = async (tenantId, locationId, data) => {
//     const response = await post(getSaveBlockApiUrl(tenantId, locationId), data);
//     return response;
// };

// export const updateBlock = async (tenantId, locationId, data) => {
//     const response = await put(getSaveBlockApiUrl(tenantId, locationId), data);
//     return response;
// };

// export const deleteBlock = async (tenantId, locationId, id) => {
//     const response = await Delete(getDeleteBlockApiUrl(tenantId, locationId, id));
//     return response;
// };
export const getAdminDetails = async (tenantId) => {
    const response = await get(getAdminDetailsApi(tenantId));
    if (!response) {
        return []
    }
    return response;
};