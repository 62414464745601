import React from "react";

import HDiv from "../components/containers/hdiv";
import VDiv from "../components/containers/vdiv";
import { Input } from "../components/inputs/input";
import useLogin from "../hooks/useLogin";
import FormContainer from "../components/containers/form";

import { FormProvider } from "react-hook-form";

import {
  email_validation,
  password_validation_2,
} from "../utils/inputValidations";
import FormError from "../components/inputs/form-error";
import ProcessLoader from "../components/general/process-loader";

export default function Login() {
  const {
    methods,
    onSubmit,
    errorMessage,
    showErrorMessage,
    onInputChange,
    apiInProgress,
  } = useLogin();

  return (
    <HDiv className="page-form-container">
      <VDiv className="page-form-banner">
        <VDiv className="sliders">
          <VDiv className="slider slider1"></VDiv>
        </VDiv>
      </VDiv>
      <FormContainer
        showLogo
        title="Welcome to GJ Concierge"
        subTitle="Sign in to your account"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => e.preventDefault()}
            onChange={(event) => onInputChange(event)}
            noValidate
            autoComplete="off"
            className="container"
          >
            <Input {...email_validation} className="mb-3" />
            <Input
              {...password_validation_2}
              className="mb-3"
              showEyeIcon="true"
              inDiv={"d-flex align-items-center position-relative"}
            />

            <FormError
              className="error"
              message={errorMessage}
              show={showErrorMessage}
            />
            <button
              className={`edc-btn-secondary mb-3 col-md-12 mt-3`}
              onClick={onSubmit}
            >
              SIGN IN
            </button>
          </form>
        </FormProvider>
        {apiInProgress && <ProcessLoader />}
      </FormContainer>
    </HDiv>
  );
}
