import React, { useEffect } from "react";
import Layout from "../components/layout/layout";

// import DashboardMessage from "../components/dashboard-message";
import RequestCounts from "../components/request-counts";
// import useDashboard from "../hooks/useDashboard";

export default function Dashboard() {
  // const { tenantId, locationId } = useDashboard();
  useEffect(() => {
    localStorage.setItem("menuName", "menuName0");
  }, []);
  return (
    <Layout>
      <RequestCounts />
      {/* <DashboardMessage tenantId={tenantId} locationId={locationId} /> */}
      {/* <ServiceRequestList /> */}
    </Layout>
  );
}
